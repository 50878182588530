<template>
  <div>
    <el-skeleton style="width: 800px">
      <template slot="template">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-tag
            type="primary"
            effect="dark"
            size="small"
            style="margin-top:15px"
            >{{ bannerData.type }}</el-tag>
          </el-col>
          <el-col :span="16">
        <h3 style="text-align: center; font-family: Sans-serif, Verdana; color: black">
          {{ bannerData.name_mon }}
        </h3>
        </el-col>
        <el-col :span="4">
          <el-button
            style="margin-top:14px"
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="deleteBannerChild"
            plain>
          </el-button>
          </el-col>
        </el-row>
          <el-row :gutter="20">
            <el-col>
            <div class="center">
            <el-carousel indicator-position="outside">
           <el-image
              v-if="bannerData && bannerData.data && Object.prototype.hasOwnProperty.call(bannerData.data, 'image_url')"
              class="center"
              :src="bannerData.data.image_url"
              fit="contain">
            </el-image>
            </el-carousel>
                </div>
            </el-col>
          </el-row>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  props: {
    bannerData: {
      type: Object,
      default: () => {}
    },
    deleteBanner: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    deleteBannerChild () {
      this.$emit('deleteBanner')
    }
  }
}

</script>
<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
