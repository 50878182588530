<template>
  <div>
    <custom-header :title="'Collection үүсгэх'"/>
    <el-row>
      <el-col :span="20" :offset="2">
        <!-- form error template -->
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="collectionData" ref="collectionData" v-loading="loading">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                </div>
                <page-items :data="collectionData.items" @update:data="dataUpdate" :updateFunction="dataUpdate"></page-items>
              </div>
            </el-col>
            <div class="panel-item">
              <el-col :span="8">
                <el-row :gutter="20">
                  <el-button
                    style="width: 100%; margin-top: 20px" type="primary" @click="addBanner">Баннер нэмэх</el-button>
                </el-row>
                <el-form>
                  <div class="panel" style="margin-top: 30px">
                    <div class="panel-item">
                      <el-row :gutter="20" class="mt20 pl10">
                        <el-col :span="12">
                          <h4>Монгол нэр</h4>
                          <el-input
                            type="text" size="mini" placeholder="Монгол нэр" v-model="collectionData.name_mon"></el-input>
                        </el-col>
                        <el-col :span="12">
                          <h4>Англи нэр</h4>
                          <el-input
                            type="text" size="mini" placeholder="Англи нэр" v-model="collectionData.name_eng"></el-input>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20" class="mt20 pl10">
                        <el-col :span="12">
                          <h4>Гарчиг</h4>
                          <el-input
                            type="text" size="mini" placeholder="Гарчиг оруулах" v-model="collectionData.title"></el-input>
                        </el-col>
                        <el-col :span="12">
                          <h4>Slug</h4>
                          <el-input
                            type="text" size="mini" placeholder="Slug оруулах..." v-model="collectionData.slug"></el-input>
                        </el-col>
                      </el-row>
                      <el-row :gutter="35" class="mt20 pl10">
                      <el-col :span="12">
                        <h4>Төлөв</h4>
                        <el-switch
                        v-model="collectionData.status"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-value="active"
                        inactive-value="inactive"
                        active-text="Идэвхтэй"
                        inactive-text="Идэвхгүй">
                        </el-switch>
                      </el-col>
                        <el-col :span="12">
                          <h4>Эрэмбэ</h4>
                          <el-input
                            type="text" size="mini" placeholder="Эрэмбэ оруулах..." v-model="collectionData.sort"></el-input>
                        </el-col>
                      </el-row>
                       <el-row :gutter="20" class="mt20 pl10">
                    <el-col :span="8">
                      <el-form-item label="Эхлэх огноо">
                        <el-date-picker
                          size="small"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          type="datetime"
                          v-model="collectionData.start_date"
                          placeholder="Огноо сонгоно уу"
                          >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="6">
                      <el-form-item label="Зураг">
                        <el-upload
                          list-type="picture-card"
                          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                          action=""
                          :auto-upload="false"
                          :file-list="tempImage"
                          :on-remove="(file) => handleRemove(file)"
                          :on-change="(file) => getFile(file)">
                        <i class="el-icon-plus"></i>
                        </el-upload>
                        <loader :active="uploading"/>
                      </el-form-item>
                    </el-col>
                       </el-row>
                       <div class="mt20 pl10">
                         <el-checkbox v-model="checked">Дуусах огноо сонгох</el-checkbox>
                       </div>
                       <el-row :gutter="20" class="mt20 pl10">
                    <el-col :span="8">
                      <div v-if="checked">
                      <el-form-item label="Дуусах огноо">
                        <el-date-picker
                          size="small"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="collectionData.end_date"
                          type="datetime"
                          placeholder="Огноо сонгоно уу">
                        </el-date-picker>
                      </el-form-item>
                      </div>
                    </el-col>
                  </el-row>
                    </div>
                  </div>
                </el-form>
              </el-col>
            </div>
          </el-row>
        </el-form>
        <div class="text-right">
          <el-button style="margin-top: 20px" type="defult" @click="back">Буцах</el-button>
          <el-button type="success"
            @click="addCollection">Хадгалах</el-button>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="Баннер нэмэх"
      :visible.sync="dialogVisible"
      width="30%">
      <el-form label-position="right" label-width="100px" :model="item">
        <el-form-item label="Нэр">
            <el-input size="smill" placeholder="Нэрээ оруулна уу..." v-model="item.name_mon"></el-input>
        </el-form-item>
        <el-form-item v-if="item.type === 'banner'" label="Баннер">
          <el-select
          value-key="id"
          v-model="item.data_id"
          filterable
          placeholder="Баннер сонгох"
          size="smill"
        class="block" @change="selectedBanner(banners)">
            <el-option
            v-for="(banner, index) in banners"
            :key="index" :label="banner.banner_code"
            :value="banner.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Буцах</el-button>
        <el-button type="primary" @click="addBannerItem">Нэмэх</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pageItems from '../Collection/components/pageItems.vue'
import CustomHeader from '../../../components/common/customHeader'
import services from '../../../helpers/services'
import { handlers } from '../../../helpers/custom'
import Loader from '../../../components/common/loader'
export default {
  name: 'addCollection',
  components: {
    CustomHeader,
    pageItems,
    Loader
  },
  watch: {
    'collectionData.items': function (val) {
      this.collectionData.items.forEach((item, index) => {
        this.collectionData.items[index].sort = index
      })
    }
  },
  created () {
    if (this.$route.params.slug) {
      this.getOneCollection(this.$route.params.slug)
    }
    this.getBanner()
  },
  data () {
    return {
      uploading: false,
      tempImage: [],
      dialogVisible: false,
      item: {},
      banners: [],
      checked: false,
      collectionData: {
        collection_pic: '',
        name_eng: '',
        name_mon: '',
        start_date: '',
        end_date: null,
        slug: '',
        items: [],
        sort: 0,
        status: false
      },
      // statusSelect: [{
      //   value: 'active',
      //   label: 'Идэвхтэй'
      // }, {
      //   value: 'inactive',
      //   label: 'Идэвхгүй'
      // }],
      errorArray: [],
      loading: false
    }
  },
  methods: {
    getFile (file) {
      handlers.checkImageSize(file, true, 550, 370).then(() => {
        this.uploading = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            this.uploading = false
            if (response.status === 'success') {
              this.tempImage.push({
                url: response.url
              })
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file)
        this.$message.error(error.message)
      })
    },
    handleRemove (file) {
      var tempId = 0
      this.tempImage.forEach((element, index) => {
        if (element.uid === file.uid) {
          tempId = index
        }
      })
      this.tempImage.splice(tempId, 1)
    },
    addItem () {
      this.item.id = this.collectionData.items.length + 1
      this.collectionData.items.push(this.item)
      this.dialogVisible = false
    },
    dataUpdate: function (data) {
      this.collectionData.items = data
    },
    addBanner () {
      this.item = {
        type: 'banner'
      }
      this.dialogVisible = true
    },
    selectedBanner (banners) {
      this.item.data = banners.find(x => x.id === this.item.data_id)
    },
    addBannerItem () {
      this.collectionData.items.push(this.item)
      this.dialogVisible = false
    },
    addCollection () {
      try {
        if (this.collectionData.end_date === null) {
          delete this.collectionData.end_date
        }
        const tempBody = JSON.parse(JSON.stringify(this.collectionData))
        tempBody.collection_pic = this.tempImage[0].url
        tempBody.items.forEach(el => {
          delete el.data
        })
        services.addCollection(tempBody).then((data) => {
          if (data.status === 'success') {
            this.alertReporter('Амжилттай', data.message, 'success')
            this.loading = false
            this.$router.push({ name: 'collectionList' })
          } else {
            this.alertReporter('Алдаа', data.message, 'error')
          }
        })
      } catch (err) {
        this.alertReporter('Алдаа', err, 'error')
      }
    },
    getBanner (query) {
      this.loading = true
      services.getBanner(1000, 0, query).then(response => {
        if (response.status === 'success') {
          this.totalCount = response.total
          this.banners = response.data
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    back () {
      this.$router.go(-1)
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>
<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
