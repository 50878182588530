<template>
  <div>
    <div class="panel-item">
      <el-row :gutter="20">
        <el-col :span="8">
          <draggable v-model="currentData" @change="sendResultValues">
            <transition-group>
              <div v-for="(element, index) in currentData" :key="'asdasd' + index">
                <el-row v-if="element.type === 'banner'" :gutter="20">
                  <banner :bannerData="element" @deleteBanner="deleteItem(index)"></banner>
                </el-row>
              </div>
            </transition-group>
          </draggable>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import banner from './banner.vue'
import draggable from 'vuedraggable'
export default {
  name: 'pageItems',
  components: {
    banner,
    draggable
  },
  watch: {
    data (oldVal, newVal) {
      this.currentData = JSON.parse(JSON.stringify(newVal))
      this.currentData = oldVal
    }
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    updateFunction: Function,
    is_active: {
      type: Boolean,
      default: false
    },
    title: {
      require: true,
      type: String
    },
    type: {
      require: true,
      type: String
    }
  },
  data () {
    return {
      currentData: []
    }
  },
  methods: {
    sendResultValues: function () {
      // this.$emit('update:data', this.data)
      this.updateFunction(this.currentData)
    },
    deleteItem (index) {
      this.currentData.splice(index, 1)
    }
  }
}

</script>
